import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

const LANGUAGE_TEXT: { [key: string]: string } = {
  en: "English",
  jp: "日本語",
  hk: "繁體中文",
};

const JAPANESE = ["ja", "ja-JP", "jp"];
const HONGKONG = ["zh", "zh-CN", "zh-HK", "zh-MO", "zh-SG", "zh-TW", "hk"];
const DropdownLang: React.FC = () => {
  const { i18n } = useTranslation();

  const [languageSelect, set_languageSelect] = useState<string>("en");
  const [onActivate, set_onActivate] = useState<boolean>(false);

  const handleClickDropDown = () => {
    set_onActivate(!onActivate);
  };

  const handleSelectLang = (input: string) => {
    set_languageSelect(input);
    set_onActivate(false);
    i18n.changeLanguage(input);
  };

  useEffect(() => {
    console.log("navigator: ", window.navigator.language);
    console.log("local storage: ", window.localStorage.i18nextLng);
    if (
      JAPANESE.includes(window.navigator.language) ||
      JAPANESE.includes(window.localStorage.i18nextLng)
    ) {
      i18n.changeLanguage("jp");
      set_languageSelect("jp");
    } else if (
      HONGKONG.includes(window.navigator.language) ||
      HONGKONG.includes(window.localStorage.i18nextLng)
    ) {
      i18n.changeLanguage("hk");
      set_languageSelect("hk");
    } else {
      i18n.changeLanguage("en");
    }
  }, []);

  return (
    <div className="relative inline-block text-left w-auto my-4 z-10">
      <span className="rounded-md">
        <button
          className="z-10 cursor-pointer justify-between items-center w-full px-4 py-2 font-medium leading-5 text-gray-700 transition duration-150 ease-in-out rounded-md hover:text-gray-500 focus:outline-none active:bg-gray-50 active:text-gray-800 text-lg flex flex-row"
          type="button"
          aria-haspopup="true"
          aria-expanded="true"
          aria-controls="headlessui-menu-items-117"
          onClick={handleClickDropDown}
        >
          <p className="lg:text-xl md:text-lg sm:text-sm">{LANGUAGE_TEXT[languageSelect]}</p>
          <svg
            className={`w-5 h-5 ml-2 -mr-1 transform duration-300 align-middle ${
              onActivate ? "rotate-180" : ""
            }`}
            viewBox="0 0 20 20"
            fill="currentColor"
          >
            <path
              fillRule="evenodd"
              d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
              clipRule="evenodd"
            ></path>
          </svg>
        </button>
      </span>
      <div
        className="w-full opacity-0 invisible transition-all duration-300 transform origin-top-right -translate-y-2"
        style={
          onActivate
            ? {
                opacity: "1",
                transform: "translate(0, 0) scale(1)",
                visibility: "visible",
              }
            : {}
        }
      >
        <div
          className="absolute left-0 w-full mt-2 origin-top-right bg-white border border-gray-200 divide-y divide-gray-100 rounded-md shadow-lg outline-none"
          aria-labelledby="headlessui-menu-button-1"
          id="headlessui-menu-items-117"
          role="menu"
        >
          <div className="py-1">
            {Object.entries(LANGUAGE_TEXT).map(
              ([key, lang], index) =>
                key !== languageSelect && (
                  <button
                    tabIndex={index}
                    className="text-gray-700 flex w-full px-4 py-2 text-sm leading-5 text-center justify-center"
                    onClick={() => handleSelectLang(key)}
                    key={index}
                  >
                    {lang}
                  </button>
                )
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default DropdownLang;
