import { AnimationSegment } from "lottie-web";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useRecoilState, useRecoilValue } from "recoil";
import SVGResponsivePlayer from "../components/SVGResponsivePlayer";
import { isMobile, pageStateAtom } from "../atom";
import { useTranslation } from "react-i18next";

const SEGMENT_LANGUAGE: Record<string, AnimationSegment> = {
  en: [0, 274],
  hk: [275, 549],
  jp: [550, 822],
};

const YOUTUBE_URL: Record<string, string> = {
  en: "https://www.youtube.com/embed/BEdRcGWJ_DQ",
  jp: "https://www.youtube.com/embed/1rXV5mnKpNM",
  hk: "https://www.youtube.com/embed/P6Uj-MztmiI",
};

const YOUTUBE_URL_MOBILE: Record<string, string> = {
  en: "https://youtu.be/BEdRcGWJ_DQ",
  jp: "https://youtu.be/1rXV5mnKpNM",
  hk: "https://youtu.be/P6Uj-MztmiI",
};

const Signtown = () => {
  const isMobile_ = useRecoilValue(isMobile);
  const { t, i18n } = useTranslation();
  const [isModal, set_isModal] = useState<boolean>(false);

  const [, set_pageState] = useRecoilState(pageStateAtom);

  const handleClickPlayExperience = () => {
    window.open("https://sign.town", "_blank");
  };

  const handleClickWatch = () => {
    set_isModal(true);
  };

  const handleCloseModal = () => {
    set_isModal(false);
  };

  useEffect(() => {
    const modalVideo = document.getElementById("modal-video");
    const modalBg = document.getElementById("modal-black-screen");
    const video_iframe = document.getElementById("youtube_player");

    if (video_iframe && modalVideo && modalBg) {
      if (isModal) {
        modalVideo.classList.add("-translate-y-1/2");
        modalVideo.classList.add("opacity-100");
        modalVideo.classList.add("z-50");
        modalVideo.classList.remove("translate-y-full");
        modalVideo.classList.remove("opacity-0");
        modalVideo.classList.remove("z-0");

        modalBg.classList.add("opacity-75");
        modalBg.classList.add("z-40");
        modalBg.classList.remove("opacity-0");
        modalBg.classList.remove("z-0");
        if (video_iframe) {
          (video_iframe as any).src = YOUTUBE_URL[i18n.language];
        }
      } else {
        modalVideo.classList.remove("-translate-y-1/2");
        modalVideo.classList.remove("opacity-100");
        modalVideo.classList.remove("z-50");
        modalVideo.classList.add("translate-y-full");
        modalVideo.classList.add("opacity-0");
        modalVideo.classList.add("z-0");

        modalBg.classList.remove("opacity-75");
        modalBg.classList.remove("z-40");
        modalBg.classList.add("opacity-0");
        modalBg.classList.add("z-0");

        if (video_iframe) {
          (video_iframe as any).src = "";
        }
      }
    }
  }, [isModal]);

  if (!isMobile_)
    return (
      <div
        className="overflow-hidden w-screen h-screen relative"
        style={{ backgroundColor: "#F5F5F5" }}
      >
        <div
          id="modal-black-screen"
          className="bg-gray-500 opacity-75 w-full h-full absolute z-40 duration-500"
          onClick={handleCloseModal}
        />
        <div
          id="modal-video"
          className="absolute transform left-1/2 top-1/2 -translate-x-1/2 translate-y-full z-0 opacity-0 p-16 bg-white rounded-3xl duration-300"
        >
          <iframe
            id="youtube_player"
            width="640"
            height="480"
            src={YOUTUBE_URL[i18n.language]}
            title="Signtown english"
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
          ></iframe>
        </div>
        <Link to={"/"} onClick={() => set_pageState("home")}>
          <img
            alt="left arrow icon"
            className="absolute lg:left-24 left-8 top-28"
            src="./assets/icons/left_arrow.svg"
          />
        </Link>
        <div className="absolute lg:top-24 md:top-24 top-16 lg:left-48 left-24">
          <h1 className="lg:text-5xl md:text-4xl text-3xl mb-4 font-quicksand">
            {t("signtown.title")}
          </h1>
          <p className="whitespace-pre-line lg:text-2xl md:text-xl text-lg leading-8">
            {t("signtown.subtitle")}
          </p>
        </div>
        <div
          className="w-full h-2/3 absolute left-1/2 transform -translate-x-1/2"
          style={{ bottom: "-8%" }}
        >
          <SVGResponsivePlayer
            src={`/assets/lottie/export_Sign.json`}
            fitBy={"width"}
            side={1024 * 0.75}
            segments={[SEGMENT_LANGUAGE[i18n.language]]}
          />
        </div>
        <div
          className="z-10 w-full h-2/3 absolute left-1/2 transform -translate-x-1/2"
          style={{ bottom: "-8%" }}
        >
          <SVGResponsivePlayer
            src={`/assets/lottie/export_Home.json`}
            fitBy={"width"}
            side={1024 * 0.75}
          />
        </div>
        <div
          className="border-solid border-black h-1/4 absolute -bottom-1 left-0 w-full text-lg"
          style={{ backgroundColor: "#DBD6D6", borderTopWidth: "8px", boxSizing: "content-box" }}
        >
          <div
            className="flex flex-row xl:w-1/3 lg:w-1/2 md:w-1/2 w-2/3 m-auto justify-between items-center h-full"
            style={{ color: "#3C4043" }}
          >
            <div
              className="flex flex-row items-center cursor-pointer z-20"
              onClick={handleClickWatch}
            >
              <div className="relative mr-4 h-12 w-12">
                <div
                  className="absolute top-1/2 left-1/2 transform -translate-y-1/2 -translate-x-1/2 w-6 h-6"
                  style={{ zIndex: -1, backgroundColor: "#8A8A8A" }}
                />
                <img
                  alt="watch video icon"
                  src="./assets/icons/signtown_watchVideo.svg"
                  className="h-full w-auto"
                />
              </div>
              <p>{t("signtown.watch")}</p>
            </div>
            <div
              className="flex flex-row items-center cursor-pointer z-20"
              onClick={handleClickPlayExperience}
            >
              <div className="relative mr-4 h-12 w-12">
                <div
                  className="absolute top-1/2 left-1/2 transform -translate-y-1/2 -translate-x-1/2 w-6 h-6"
                  style={{ zIndex: -1, backgroundColor: "#8A8A8A" }}
                />
                <img
                  alt="play demo icon"
                  src="./assets/icons/signtown_playExperience.svg"
                  className="h-full w-auto"
                />
              </div>
              <p>{t("signtown.play")}</p>
            </div>
          </div>
        </div>
      </div>
    );
  else
    return (
      <div className="overflow-x-hidden w-full h-full relative" style={{ backgroundColor: "#fff" }}>
        <Link to={"/"} onClick={() => set_pageState("home")}>
          <img
            alt="left arrow icon"
            className="absolute left-8 top-12 w-4"
            src="./assets/icons/left_arrow.svg"
          />
        </Link>
        <div className="absolute left-8 top-24">
          <h1 className="text-3xl mb-4 font-quicksand">{t("signtown.mobile.title")}</h1>
          <p className="whitespace-pre-line text-base leading-6 mr-8">
            {t("signtown.mobile.subtitle")}
          </p>
        </div>
        <div className="w-full h-1/2 overflow-hidden absolute left-1/2 transform -translate-x-1/2 top-1/2">
          <SVGResponsivePlayer
            src={`/assets/lottie/export_Sign.json`}
            fitBy={"width"}
            side={1024 * 0.75}
            segments={[SEGMENT_LANGUAGE[i18n.language]]}
          />
        </div>
        <div className="z-10 w-full h-1/2 overflow-hidden absolute left-1/2 transform -translate-x-1/2 top-1/2">
          <SVGResponsivePlayer
            src={`/assets/lottie/export_Home.json`}
            fitBy={"width"}
            side={1024 * 0.75}
          />
        </div>
        <div
          className="border-solid border-black h-1/4 absolute -bottom-1 left-0 w-full text-lg"
          style={{ backgroundColor: "#DADADA", borderTopWidth: "6px", boxSizing: "content-box" }}
        >
          <div
            className="flex flex-col w-full h-1/2 mt-4 pl-12 justify-around"
            style={{ color: "#3C4043" }}
          >
            <div
              className="flex flex-row items-center cursor-pointer z-20"
              onClick={() => window.open(YOUTUBE_URL_MOBILE[i18n.language], "_blank")}
            >
              <div className="relative mr-4 h-7 w-7">
                <div
                  className="absolute top-1/2 left-1/2 transform -translate-y-1/2 -translate-x-1/2 w-4 h-4"
                  style={{ zIndex: -1, backgroundColor: "#8A8A8A" }}
                />
                <img
                  alt="watch video icon"
                  src="./assets/icons/signtown_watchVideo.svg"
                  className="h-full w-auto"
                />
              </div>
              <p>{t("signtown.watch")}</p>
            </div>
            <div
              className="flex flex-row items-center cursor-pointer z-20"
              onClick={handleClickPlayExperience}
            >
              <div className="relative mr-4 h-7 w-7">
                <div
                  className="absolute top-1/2 left-1/2 transform -translate-y-1/2 -translate-x-1/2 w-4 h-4"
                  style={{ zIndex: -1, backgroundColor: "#8A8A8A" }}
                />
                <img
                  alt="play demo icon"
                  src="./assets/icons/signtown_playExperience.svg"
                  className="h-full w-auto"
                />
              </div>

              <p>{t("signtown.playmobile")}</p>
            </div>
          </div>
        </div>
      </div>
    );
};

export default Signtown;
