import React from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { useRecoilState, useRecoilValue } from "recoil";
import { isMobile, pageStateAtom } from "../atom";
import SVGResponsivePlayer from "../components/SVGResponsivePlayer";

const Signdict = () => {
  const isMobile_ = useRecoilValue(isMobile);
  const [, set_pageState] = useRecoilState(pageStateAtom);
  const { t } = useTranslation();

  const handleClickPlayExperience = () => {
    window.open("https://handbook.sign.town", "_blank");
  };

  if (!isMobile_)
    return (
      <div
        className="overflow-hidden w-screen h-screen relative"
        style={{ backgroundColor: "#F2F6F8" }}
      >
        <Link to={"/"} onClick={() => set_pageState("home")}>
          <img
            alt="left arrow icon"
            className="absolute top-12 py-16 px-12 z-40 2xl:px-24"
            src="./assets/icons/left_arrow.svg"
          />
        </Link>
        <div className="flex flex-row absolute top-24 2xl:left-48 left-24 h-auto z-10">
          <div className="w-24 h-24 relative 2xl:w-32">
            <div className="w-32 h-32 2xl:w-40 2xl:h-40 absolute top-0 left-1/2 transform -translate-x-1/2 -translate-y-1/3">
              <SVGResponsivePlayer
                src={`/assets/lottie/export_hand.json`}
                fitBy={"width"}
                side={1024 * 0.75}
              />
            </div>
          </div>
          <div className="flex flex-col">
            {/* <img
              alt="sign dictionary logo"
              src="./assets/signdict_logo.png"
              className="h-auto object-contain w-16 mr-4"
            /> */}
            <h1 className="font-quicksand lg:text-5xl md:text-4xl text-3xl mb-4">
              {t("signdict.title")}
            </h1>
            <p className="whitespace-pre-line lg:text-2xl md:text-xl text-lg leading-8">
              {t("signdict.subtitle")}
            </p>
          </div>
        </div>
        {/* <p className="absolute left-48 bottom-24 text-xl">{t("signdict.comingsoon")}</p> */}
        <div
          className="absolute left-48 bottom-24 text-xl flex flex-row items-center cursor-pointer z-20"
          onClick={handleClickPlayExperience}
        >
          <div className="relative mr-4 h-12 w-12">
            <div
              className="absolute top-1/2 left-1/2 transform -translate-y-1/2 -translate-x-1/2 w-6 h-6"
              style={{ zIndex: -1, backgroundColor: "#8A8A8A" }}
            />
            <img
              alt="play demo icon"
              src="./assets/icons/signtown_playExperience.svg"
              className="h-full w-auto"
            />
          </div>
          <p>{t("signdict.play")}</p>
        </div>
        <div
          className=" w-1/3 flex flex-row h-full absolute right-0 min-w-max z-0"
          style={{ backgroundColor: "#F9F9F9" }}
        >
          <video
            src="./assets/videos/scroll_new_ui.mp4"
            autoPlay
            loop
            playsInline
            muted
            className="lg:w-full lg:object-cover mx-auto md:object-contain md:w-64 w-64 object-contain"
          />
        </div>
      </div>
    );
  else
    return (
      <div
        className="overflow-x-hidden w-full h-full relative"
        style={{ backgroundColor: "#F2F6F8" }}
      >
        <div className="relative w-full h-1/3">
          <Link to={"/"} onClick={() => set_pageState("home")}>
            <img
              alt="left arrow icon"
              className="absolute left-8 w-4 z-40 mt-8"
              // style={{ top: "12%" }}
              src="./assets/icons/left_arrow.svg"
            />
          </Link>
          {/* <p className="absolute right-8 text-base" style={{ top: "12%" }}>
            {t("signdict.comingsoon")}
          </p> */}
          <div
              className="absolute right-8 mt-5 text-base flex flex-row items-center cursor-pointer z-20"
              onClick={handleClickPlayExperience}
            >
              <p>{t("signdict.play")}</p>
              <div className="relative ml-4 h-12 w-12">
                <div
                  className="absolute top-1/2 left-1/2 transform -translate-y-1/2 -translate-x-1/2 w-6 h-6"
                  style={{ zIndex: -1, backgroundColor: "#8A8A8A" }}
                />
                <img
                  alt="play demo icon"
                  src="./assets/icons/signtown_playExperience.svg"
                  className="h-full w-auto"
                />
              </div>
            </div>
          <div className="px-8 flex flex-col absolute top-1/3 h-auto">
            <div className="flex flex-row items-center align-middle mb-2">
              {/* <img
                alt="sign dictionary logo"
                src="./assets/signdict_logo.png"
                className="h-auto object-contain w-16 mr-4"
              /> */}
              <div className="w-1/4 relative">
                <div className="w-28 h-28 absolute left-1/2 transform -translate-x-1/2 -translate-y-1/2">
                  <SVGResponsivePlayer
                    src={`/assets/lottie/export_hand.json`}
                    fitBy={"width"}
                    side={1024 * 0.75}
                  />
                </div>
              </div>
              <h1 className="font-quicksand text-2xl whitespace-pre">
                {t("signdict.mobile.title")}
              </h1>
            </div>
            <div>
              <p className="whitespace-pre-wrap text-base leading-6 ml-4 my-0 w-5/6">
                {t("signdict.mobile.subtitle")}
              </p>
            </div>
          </div>

        </div>
        <div className="flex flex-row w-full h-2/3 absolute top-1/3 rounded-t-xl overflow-x-hidden transform translate-y-1">
          <video
            src="./assets/videos/scroll_new_ui.mp4"
            autoPlay
            loop
            playsInline
            muted
            className="w-full object-cover object-top mx-auto"
          />
          {/* <div className="flex flex-col w-10 h-auto absolute space-y-2 right-4 top-4">
            <img alt="search icon" src="./assets/icons/signdict_search.svg" className="w-10 h-10" />
            <div className="w-10 h-10 rounded-full bg-white flex justify-center items-center">
              <img
                alt="camera icon"
                src="./assets/icons/signdict_camera.svg"
                className="w-6 h-6"
                style={{ filter: "grayscale(100%) contrast(300%)" }}
              />
            </div>
            <div className="w-10 h-10 rounded-full bg-white flex justify-center items-center">
              <img
                alt="text icon"
                src="./assets/icons/signdict_text.png"
                className="w-6 h-6"
                style={{ filter: "grayscale(100%)" }}
              />
            </div>
          </div> */}
        </div>
      </div>
    );
};

export default Signdict;
