import React from "react";
import { useRef } from "react";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useRecoilState } from "recoil";
import { pageStateAtom } from "../atom";
import SVGResponsivePlayer from "./SVGResponsivePlayer";

const Signdict_card = () => {
  const [pageState, set_pageState] = useRecoilState(pageStateAtom);
  const signdictCardRef = useRef<HTMLDivElement>(null);

  const handle_clickSigndict = () => {
    if (pageState === "signdict") set_pageState("home");
    else set_pageState("signdict");
  };

  useEffect(() => {
    switch (pageState) {
      case "home":
        break;
      case "signtown":
        break;
      case "signdict":
        break;
    }
  }, [pageState]);

  const { t } = useTranslation();

  return (
    <div
      className={`duration-300 transform  overflow-hidden relative h-full flex justify-center items-center`}
      style={{ backgroundColor: "#F2F6F8" }}
      onClick={handle_clickSigndict}
      ref={signdictCardRef}
    >
      <h2 className="font-quicksand text-left transform align-middle whitespace-pre absolute text-xl top-4 left-8 z-10">
        {t("signdict.mobile.title")}
      </h2>
      {/* <img alt="sign dictionary logo" src="./assets/signdict_logo.png" className="w-1/5 mt-8" /> */}
      <div className="w-1/2 mt-12">
        <SVGResponsivePlayer
          src={`/assets/lottie/export_hand.json`}
          fitBy={"width"}
          side={1024 * 0.75}
        />
      </div>
    </div>
  );
};

export default Signdict_card;
