import { atom } from "recoil";

export const isMobile = atom<boolean | null>({
  key: "device_detect",
  default: null,
});

export const pageStateAtom = atom({
  key: "pageState",
  default: "home",
});
