import React, { useEffect, useRef } from "react";
import SigntownCard from "../components/Signtown_card";
import SigndictCard from "../components/Signdict_card";
import { useRecoilState, useRecoilValue } from "recoil";
import { isMobile, pageStateAtom } from "../atom";
import About from "../components/About";
import Signtown from "../components/Signtown";
import Signdict from "../components/Signdict";
import { useState } from "react";
import DropdownLang from "../components/Dropdown";

import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import SVGResponsivePlayer from "../components/SVGResponsivePlayer";

const PARTNER: { [id: string]: string } = {
  nippon: "./assets/partners/nippon.svg",
  cuhk: "./assets/partners/cuhk.svg",
  cslds: "./assets/partners/CSLDS.png",
  google: "./assets/partners/google.png",
  kwansei: "./assets/partners/kwansei.svg",
};

const Main = () => {
  const isMobile_ = useRecoilValue(isMobile);
  const [pageState, set_pageState] = useRecoilState(pageStateAtom);
  const [showAbout, set_showAbout] = useState(false);
  const [desktopState, set_desktopState] = useState("signtown");
  const [hoverDropdown, set_hoverDropdown] = useState<boolean>(false);
  const titleRef = useRef<HTMLDivElement>(null);
  const history = useHistory();

  const { t, i18n } = useTranslation();

  useEffect(() => {
    console.log("loadpage");
    switch (pageState) {
      case "home":
        break;
      case "signtown":
        break;
      case "signdict":
        break;
    }
  }, [pageState]);

  const handleHover = (page: string) => {
    set_desktopState(page);
  };

  const handleClickpage = (page: string) => {
    if (hoverDropdown) return;
    set_pageState(page);
    history.push(page);
  };

  const onHoverDropdown = () => {
    set_hoverDropdown(!hoverDropdown);
  };

  const mobileClickShuwa = () => {
    set_showAbout(!showAbout);
  };

  function getWindowDimensions() {
    const { innerWidth: width, innerHeight: height } = window;
    return {
      width,
      height,
    };
  }

  useEffect(() => {
    const signdictWrapper = document.getElementById("signcard-wrapper");
    const mobileAboutArrow = document.getElementById("mobile-about-arrow");
    const mobileAboutContent = document.getElementById("mobile-about");

    if (showAbout) {
      signdictWrapper?.classList.add("translate-y-full");
      mobileAboutArrow?.classList.add("top-full");
      mobileAboutArrow?.classList.add("rotate-90");
      mobileAboutArrow?.classList.add("-translate-y-8");
      mobileAboutArrow?.classList.remove("translate-y-0");
      mobileAboutArrow?.classList.remove("top-1/4");
      mobileAboutArrow?.classList.remove("-rotate-90");
      mobileAboutArrow?.classList.remove("xs:translate-y-1/2");

      mobileAboutContent?.classList.add("opacity-100");
      mobileAboutContent?.classList.add("translate-y-0");
      mobileAboutContent?.classList.remove("opacity-0");
      mobileAboutContent?.classList.remove("translate-y-2");
    } else {
      signdictWrapper?.classList.remove("translate-y-full");
      mobileAboutArrow?.classList.remove("top-full");
      mobileAboutArrow?.classList.remove("rotate-90");
      mobileAboutArrow?.classList.remove("-translate-y-8");
      mobileAboutArrow?.classList.add("translate-y-0");
      mobileAboutArrow?.classList.add("top-1/4");
      mobileAboutArrow?.classList.add("-rotate-90");
      mobileAboutArrow?.classList.add("xs:translate-y-1/2");

      mobileAboutContent?.classList.remove("opacity-100");
      mobileAboutContent?.classList.remove("translate-y-0");
      mobileAboutContent?.classList.add("opacity-0");
      mobileAboutContent?.classList.add("translate-y-2");
    }
  }, [showAbout]);

  const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());

  useEffect(() => {
    function handleResize() {
      setWindowDimensions(getWindowDimensions());
    }

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  if (isMobile_) {
    return (
      <div className={`overflow-x-hidden App w-full h-full relative`}>
        <div className="absolute top-4 right-4">
          <DropdownLang />
        </div>
        <div
          className={`w-full h-full absolute overflow-hidden`}
          ref={titleRef}
          onClick={mobileClickShuwa}
        >
          <div className="flex flex-col mx-8 justify-center absolute h-1/3">
            {/* TODO: rpx top-16 */}
            {/* <img alt="shuwa hero" src="./assets/shuwa_hero_x1.png" className="w-5/6" /> */}
            {/* <div className="w-12 h-12 relative xs:w-12 xs:h-6">
              <div className="w-24 h-24 xs:h-16 absolute top-0 left-1/2 transform -translate-x-1/2 -translate-y-1/3">
                <SVGResponsivePlayer
                  src={`/assets/lottie/export_hand.json`}
                  fitBy={"width"}
                  side={1024 * 0.75}
                />
              </div>
            </div>
            <p className=" font-quicksand w-full text-left text-4xl whitespace-pre">
              {t("main.title")}
            </p>
            <p className="font-sans text-sm w-full text-left">{t("main.subtitle")}</p> */}
            <img
                alt="expanding about arrow"
                src="./assets/icons/project_shuwa.svg"
                className="h-9 w-full"
              />
          </div>
          <div
            className="flex flex-col absolute top-1/4 justify-start h-2/3 opacity-0 transform translate-y-2 duration-200"
            id="mobile-about"
          >
            <p
              className={`whitespace-pre-wrap text-left mx-8 mt-1 xs:text-xs xs:whitespace-normal xs:mt-2 ${
                i18n.language === "jp"
                  ? "text-xs leading-none"
                  : i18n.language === "hk"
                  ? "text-sm leading-normal"
                  : "text-sm leading-tight"
              }`}
            >
              {t("main.about")}
            </p>
            <div className="grid grid-cols-3 gap-4 mx-8 h-full mt-4">
              {Object.entries(PARTNER).map(([key, src], index) => (
                <div className="flex justify-center items-center">
                  <img
                    alt={`${key} logo`}
                    key={index}
                    src={src}
                    className={`${
                      key === "nippon" ? "w-12" : key === "google" ? "w-20" : "w-16"
                    } h-12 object-contain`}
                  />
                </div>
              ))}
            </div>
          </div>
          <img
            alt="expanding about arrow"
            src="./assets/icons/left_arrow.svg"
            className="w-3 absolute duration-200 transform -rotate-90 left-10 top-1/4 translate-y-0 opacity-50 xs:translate-y-1/2"
            id="mobile-about-arrow"
          />
        </div>
        <div
          className="w-full h-2/3 absolute bottom-0 transform duration-200 translate-y-1"
          id="signcard-wrapper"
        >
          <div
            className=" w-full h-full rounded-tr-3xl absolute overflow-hidden"
            style={{ backgroundColor: "#DBD6D6" }}
            onClick={() => handleClickpage("signtown")}
          >
            <SigntownCard />
          </div>
          <div
            className=" w-full h-1/2 rounded-tr-3xl absolute bottom-0 overflow-hidden"
            onClick={() => handleClickpage("signdict")}
          >
            <SigndictCard />
          </div>
        </div>
      </div>
    );
  } else
    return (
      <div className="App w-screen h-screen overflow-hidden relative">
        <div className="absolute top-8 right-24">
          <DropdownLang />
        </div>
        <div
          className={`h-screen w-screen duration-200 absolute`}
          onMouseEnter={() => handleHover("about")}
        >
          <About />
        </div>
        <div
          className={`h-5/6 w-full flex flex-row duration-200 absolute`}
          style={{
            transform:
              desktopState === "about"
                ? windowDimensions.height < 680
                  ? "translate(0%, 560px)"
                  : "translate(0%, 100%)"
                : "translate(0%, 160px)",
          }}
        >
          <div
            className={`absolute h-screen w-screen duration-200 transform z-20 cursor-pointer rounded-r-3xl shadow-2xl`}
            style={{
              backgroundColor: "#F5F5F5",
              boxShadow: "6px 4px 12px #00000021",
              transform:
                desktopState === "signdict" ? "translate(-86%, 0%)" : "translate(-15%, 0%)",
            }}
            onMouseEnter={() => handleHover("signtown")}
            onClick={() => handleClickpage("signtown")}
          >
            <Signtown />
          </div>
          <div
            className={`absolute h-screen w-screen duration-200 transform rounded-r-3xl cursor-pointer z-0`}
            style={{
              backgroundColor: "#F2F6F8",
              boxShadow: "6px 4px 12px #00000021",
            }}
            onMouseEnter={() => handleHover("signdict")}
            onClick={() => handleClickpage("signdict")}
          >
            <Signdict onHoverDropdown={onHoverDropdown} />
          </div>
        </div>
      </div>
    );
};

export default Main;
