import { AnimationSegment } from "lottie-web";

export default function isArrayElementSimilar(
  arr1: AnimationSegment[] = [],
  arr2: AnimationSegment[] = []
): boolean {
  if (arr1.length !== arr2.length) return false;
  const flat1 = arr1.flat(),
    flat2 = arr2.flat();

  for (let i = 0; i < arr1.flat().length; i++) {
    if (flat1[i] !== flat2[i]) {
      return false;
    }
  }

  return true;
}
