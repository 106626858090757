import React from "react";
import SVGResponsivePlayer from "./SVGResponsivePlayer";
import { AnimationSegment } from "lottie-web";
import { useTranslation } from "react-i18next";

const SEGMENT_LANGUAGE: Record<string, AnimationSegment> = {
  en: [0, 274],
  hk: [275, 549],
  jp: [550, 822],
};

const Signtown = () => {
  const { t, i18n } = useTranslation();
  // const isHeight = useMediaQuery("(min-height: 720px)");

  return (
    <>
      <h2
        className=" text-left transform align-middle whitespace-pre absolute right-16 text-3xl top-16"
        style={{ writingMode: "vertical-rl" }}
      >
        {t("signtown.signtownnline")}
      </h2>
      <p className="w-full h-2/3 absolute left-1/2 transform -translate-x-1/2 translate-y-1/3 text-2xl">
        {t("signtown.centertext")}
      </p>
      <div className="w-full h-2/3 absolute left-1/2 transform -translate-x-1/2 translate-y-1/2">
        <SVGResponsivePlayer
          src={`/assets/lottie/export_Sign.json`}
          fitBy={"width"}
          side={1024 * 0.75}
          segments={[SEGMENT_LANGUAGE[i18n.language]]}
        />
      </div>
      <div className="z-10 w-full h-2/3 absolute left-1/2 transform -translate-x-1/2 translate-y-1/2">
        <SVGResponsivePlayer
          src={`/assets/lottie/export_Home.json`}
          fitBy={"width"}
          side={1024 * 0.75}
        />
      </div>
      <div
        className="border-solid border-black h-1/3 absolute -bottom-1 left-0 w-full"
        style={{ backgroundColor: "#DBD6D6", borderTopWidth: "8px", boxSizing: "content-box" }}
      />
    </>
  );
};

export default Signtown;
