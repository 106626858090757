import React from "react";
import { useTranslation } from "react-i18next";
import SVGResponsivePlayer from "./SVGResponsivePlayer";

const PARTNER: { [id: string]: string } = {
  nippon: "./assets/partners/nippon.svg",
  cuhk: "./assets/partners/cuhk.svg",
  cslds: "./assets/partners/CSLDS.png",
  google: "./assets/partners/google.png",
  kwansei: "./assets/partners/kwansei.svg",
};

const About = () => {
  const { t, i18n } = useTranslation();
  return (
    <>
      <div className="flex flex-row w-5/6 m-auto py-10">
        {/* <div className="w-20 h-24 relative">
          <div className="w-28 h-28 absolute top-0 left-1/2 transform -translate-x-1/2 -translate-y-1/3">
            <SVGResponsivePlayer
              src={`/assets/lottie/export_hand.json`}
              fitBy={"width"}
              side={1024 * 0.75}
            />
          </div>
        </div> */}
        <div className="flex flex-col w-auto py-6">
          {/* <img alt="shuwa hero" src="./assets/shuwa_hero.png" className="object-contain w-80" /> */}
          {/* <p className="font-quicksand w-full text-left text-5xl">{t("main.title")}</p> */}
          <img
                alt="expanding about arrow"
                src="./assets/icons/project_shuwa.svg"
                className="h-12"
                id="mobile-about-arrow"
              />
          {/* <p className="my-2 font-sans text-base w-full text-left whitespace-pre">
            {t("main.subtitle")}
          </p> */}
        </div>
      </div>
      <div className="flex flex-row w-5/6 m-auto" style={{ height: "60%" }}>
        <div
          className={`flex flex-col w-5/6 whitespace-pre-wrap text-left mr-24 overflow-y-auto ${
            i18n.language === "jp" ? "text-base leading-normal" : "text-xl leading-8"
          }`}
        >
          {t("main.about")}
        </div>
        <div className="flex flex-col w-1/6 space-y-6">
          {Object.entries(PARTNER).map(([key, src], index) => (
            <img
              alt={`${key} logo`}
              key={index}
              src={src}
              className={`${key === "nippon" ? "w-14" : "w-24"} h-auto mx-auto`}
            />
          ))}
        </div>
      </div>
    </>
  );
};

export default About;
