import { AnimationSegment } from "lottie-web";
import React from "react";
import { useEffect } from "react";
import { useRef } from "react";
import { useTranslation } from "react-i18next";
import { useRecoilState } from "recoil";
import { pageStateAtom } from "../atom";
import SVGResponsivePlayer from "./SVGResponsivePlayer";

const SEGMENT_LANGUAGE: Record<string, AnimationSegment> = {
  en: [0, 274],
  hk: [275, 549],
  jp: [550, 822],
};

const Signtown_card = () => {
  const [pageState, set_pageState] = useRecoilState(pageStateAtom);
  const signtownCardRef = useRef<HTMLDivElement>(null);

  const { i18n } = useTranslation();

  const handle_clickSigntown = () => {
    if (pageState === "signtown") set_pageState("home");
    else set_pageState("signtown");
  };

  useEffect(() => {
    switch (pageState) {
      case "home":
        break;
      case "signtown":
        break;
      case "signdict":
        break;
    }
  }, [pageState]);

  const { t } = useTranslation();
  return (
    <div
      className={`duration-300 transform  overflow-hidden relative h-1/2`}
      style={{ backgroundColor: "#F5F5F5" }}
      onClick={handle_clickSigntown}
      ref={signtownCardRef}
    >
      <h2 className=" font-quicksand text-left transform align-middle whitespace-pre absolute text-xl top-4 left-8">
        {t("signtown.mobile.title")}
      </h2>

      <div className="w-full h-full absolute left-1/2 transform -translate-x-1/2 translate-y-1/4">
        <SVGResponsivePlayer
          src={`/assets/lottie/export_Sign.json`}
          fitBy={"width"}
          side={1024 * 0.75}
          segments={[SEGMENT_LANGUAGE[i18n.language]]}
        />
      </div>
      <div className="z-10 w-full h-full absolute left-1/2 transform -translate-x-1/2 translate-y-1/4">
        <SVGResponsivePlayer
          src={`/assets/lottie/export_Home.json`}
          fitBy={"width"}
          side={1024 * 0.75}
        />
      </div>
      <div
        className="border-solid border-black h-1/4 absolute -bottom-0.5 left-0 w-full"
        style={{ backgroundColor: "#DBD6D6", borderTopWidth: "4px", boxSizing: "content-box" }}
      />
    </div>
  );
};

export default Signtown_card;
