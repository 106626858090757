import React, { Suspense } from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { RecoilRoot } from "recoil";
import Div100vh from "react-div-100vh";

import { BrowserRouter as Router } from "react-router-dom";
import "./i18n/i18n";

ReactDOM.render(
  <React.StrictMode>
    <Suspense fallback={<p></p>}>
      <RecoilRoot>
        <Div100vh>
          <Router>
            <App />
          </Router>
        </Div100vh>
      </RecoilRoot>
    </Suspense>
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
