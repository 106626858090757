import { useEffect, useState } from "react";
import { isMobile } from "react-device-detect";
import { useRecoilState } from "recoil";
import { isMobile as isMobileAtom } from "../atom";

function IsMobile() {
  const [reactMobile, set_reactMobile] = useState<boolean>(false);
  const [, set_isMobileAtom] = useRecoilState<boolean | null>(isMobileAtom);

  useEffect(() => {
    if (isMobile) {
      set_reactMobile(true);
      set_isMobileAtom(true);
    }
  }, []);

  return reactMobile;
}

export default IsMobile;
