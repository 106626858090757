import React from "react";
import { useTranslation } from "react-i18next";
import DropdownSelection from "./DropdownSelection";
import SVGResponsivePlayer from "./SVGResponsivePlayer";

interface ISigndict {
  onHoverDropdown: () => void;
}

const Signdict: React.FC<ISigndict> = (props: ISigndict) => {
  const { t } = useTranslation();
  return (
    <>
      <h2
        className=" text-left transform align-middle whitespace-pre absolute right-16 text-3xl top-16"
        style={{
          writingMode: "vertical-rl",
        }}
      >
        {t("signdict.signdictnline")}
      </h2>
      <div className=" w-2/3 flex flex-col h-screen m-auto justify-center absolute left-1/2 transform -translate-x-1/2">
        <h1 className="lg:text-5xl md:text-4xl text-3xl z-20 translate-y-12 transform font-thin">
          {t("signdict.findit")}
        </h1>
        <div className="w-full h-2/3">
          <SVGResponsivePlayer
            src={`/assets/lottie/export_hand.json`}
            fitBy={"width"}
            side={1024 * 0.75}
          />
        </div>
      </div>
    </>
  );
};

export default Signdict;
