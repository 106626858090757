import React, { useEffect } from "react";
import "./App.css";
import Signtown from "./pages/Signtown";
import Signdict from "./pages/Signdict";
import Main from "./pages/Main";
import DeviceDetect from "./hooks/DeviceDetect";
import { useRecoilState } from "recoil";
import { pageStateAtom } from "./atom";

import { useLocation } from "react-router-dom";
import TagManager from "react-gtm-module";

const tagManagerArgs = {
  gtmId: "GTM-WG5XVQF",
};

TagManager.initialize(tagManagerArgs);

function App() {
  DeviceDetect();
  const [pageState, set_pageState] = useRecoilState(pageStateAtom);

  let location = useLocation();

  useEffect(() => {
    console.log(location);
    let page = "";
    switch (location.pathname) {
      case "/":
        set_pageState("home");
        page = "home";
        break;
      case "/signtown":
        set_pageState("signtown");
        page = "signtown";
        break;
      case "/signdict":
        set_pageState("signdict");
        page = "signdict";
        break;
      default:
        break;
    }

    if (page) {
      const args = {
        dataLayer: {
          event: "landing_page",
          page_name: page,
        },
      };
      TagManager.dataLayer(args);
    }
  }, [location]);

  useEffect(() => {
    console.log(pageState);
    const homeWrapper = document.getElementById("main-page");
    const signdictWrapper = document.getElementById("signdict-page");
    const signtownWrapper = document.getElementById("signtown-page");
    switch (pageState) {
      case "home":
        /**
         * move signtown and signdict to right
         * move home to center
         */
        homeWrapper?.classList.remove("-translate-x-full");
        homeWrapper?.classList.add("translate-x-0");

        signdictWrapper?.classList.remove("translate-x-0");
        signdictWrapper?.classList.remove("z-10");
        signdictWrapper?.classList.add("translate-x-full");

        signtownWrapper?.classList.remove("translate-x-0");
        signtownWrapper?.classList.remove("z-10");
        signtownWrapper?.classList.add("translate-x-full");

        break;
      case "signtown":
        /**
         * move home to left
         * move signtown to center
         * z-10
         * signdict z-0
         */
        homeWrapper?.classList.remove("translate-x-0");
        homeWrapper?.classList.add("-translate-x-full");

        signtownWrapper?.classList.add("translate-x-0");
        signtownWrapper?.classList.add("z-10");
        signtownWrapper?.classList.remove("translate-x-full");
        break;
      case "signdict":
        /**
         * move home to left
         * move signdict to center
         * z-10
         * signtown z-0
         */
        homeWrapper?.classList.remove("translate-x-0");
        homeWrapper?.classList.add("-translate-x-full");

        signdictWrapper?.classList.add("translate-x-0");
        signdictWrapper?.classList.add("z-10");
        signdictWrapper?.classList.remove("translate-x-full");
        break;
    }
  }, [pageState]);

  return (
    <>
      <div
        className="w-full h-full duration-200 fixed transform translate-x-full"
        id="signtown-page"
      >
        <Signtown />
      </div>
      <div
        className="w-full h-full duration-200 fixed transform translate-x-full"
        id="signdict-page"
      >
        <Signdict />
      </div>
      <div className="w-full h-full duration-200 fixed transform translate-x-0" id="main-page">
        <Main />
      </div>
    </>
  );
}

export default App;
